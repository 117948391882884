import { default as aboutTska6UMHuWMeta } from "/vercel/path0/src/pages/about.vue?macro=true";
import { default as _91slug_93zny7bE9xqjMeta } from "/vercel/path0/src/pages/artists/[slug].vue?macro=true";
import { default as indexqcGbOSNNUNMeta } from "/vercel/path0/src/pages/artists/index.vue?macro=true";
import { default as index7aRKFbPTybMeta } from "/vercel/path0/src/pages/cart/index.vue?macro=true";
import { default as indext2siMuEToKMeta } from "/vercel/path0/src/pages/checkout/index.vue?macro=true";
import { default as indexkP7wmsbr11Meta } from "/vercel/path0/src/pages/index.vue?macro=true";
import { default as _91slug_93o3VRdqMP00Meta } from "/vercel/path0/src/pages/posts/[slug].vue?macro=true";
import { default as indexwG6ue63SviMeta } from "/vercel/path0/src/pages/posts/index.vue?macro=true";
import { default as privacyKGoRMpsRpVMeta } from "/vercel/path0/src/pages/privacy.vue?macro=true";
import { default as _91slug_93YTgYUv6Ih4Meta } from "/vercel/path0/src/pages/releases/[slug].vue?macro=true";
import { default as indexOafzpqWPgfMeta } from "/vercel/path0/src/pages/releases/index.vue?macro=true";
import { default as servicesHXm8lQK6rLMeta } from "/vercel/path0/src/pages/services.vue?macro=true";
import { default as _91slug_93X87UilsBldMeta } from "/vercel/path0/src/pages/shop/[slug].vue?macro=true";
import { default as cassettesVGtI49KTueMeta } from "/vercel/path0/src/pages/shop/cassettes.vue?macro=true";
import { default as index7peolLGcLDMeta } from "/vercel/path0/src/pages/shop/index.vue?macro=true";
import { default as merchwCFRhCxJaTMeta } from "/vercel/path0/src/pages/shop/merch.vue?macro=true";
import { default as order_45success9YtXStNsKQMeta } from "/vercel/path0/src/pages/shop/order-success.vue?macro=true";
import { default as slug_45backupnt8sykPEy9Meta } from "/vercel/path0/src/pages/shop/slug-backup.vue?macro=true";
import { default as black_45metal_45cassettesZCyOXaSI5vMeta } from "/vercel/path0/src/pages/shop/tags/black-metal-cassettes.vue?macro=true";
import { default as merch_45bundlesP1uaAq2cdIMeta } from "/vercel/path0/src/pages/shop/tags/merch-bundles.vue?macro=true";
import { default as post_45metal_45cassettesUnNfIqUWm2Meta } from "/vercel/path0/src/pages/shop/tags/post-metal-cassettes.vue?macro=true";
import { default as post_45metal_45t_45shirtsZRdhx35JH9Meta } from "/vercel/path0/src/pages/shop/tags/post-metal-t-shirts.vue?macro=true";
import { default as post_45rock_45cassettesb54NN5mnCSMeta } from "/vercel/path0/src/pages/shop/tags/post-rock-cassettes.vue?macro=true";
import { default as post_45rock_45t_45shirtse9AndRRAc3Meta } from "/vercel/path0/src/pages/shop/tags/post-rock-t-shirts.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/vercel/path0/src/pages/about.vue")
  },
  {
    name: "artists-slug",
    path: "/artists/:slug()",
    component: () => import("/vercel/path0/src/pages/artists/[slug].vue")
  },
  {
    name: "artists",
    path: "/artists",
    component: () => import("/vercel/path0/src/pages/artists/index.vue")
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/vercel/path0/src/pages/cart/index.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("/vercel/path0/src/pages/checkout/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/src/pages/index.vue")
  },
  {
    name: "posts-slug",
    path: "/posts/:slug()",
    component: () => import("/vercel/path0/src/pages/posts/[slug].vue")
  },
  {
    name: "posts",
    path: "/posts",
    component: () => import("/vercel/path0/src/pages/posts/index.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/vercel/path0/src/pages/privacy.vue")
  },
  {
    name: "releases-slug",
    path: "/releases/:slug()",
    meta: _91slug_93YTgYUv6Ih4Meta || {},
    component: () => import("/vercel/path0/src/pages/releases/[slug].vue")
  },
  {
    name: "releases",
    path: "/releases",
    component: () => import("/vercel/path0/src/pages/releases/index.vue")
  },
  {
    name: "services",
    path: "/services",
    component: () => import("/vercel/path0/src/pages/services.vue")
  },
  {
    name: "shop-slug",
    path: "/shop/:slug()",
    component: () => import("/vercel/path0/src/pages/shop/[slug].vue")
  },
  {
    name: "shop-cassettes",
    path: "/shop/cassettes",
    component: () => import("/vercel/path0/src/pages/shop/cassettes.vue")
  },
  {
    name: "shop",
    path: "/shop",
    component: () => import("/vercel/path0/src/pages/shop/index.vue")
  },
  {
    name: "shop-merch",
    path: "/shop/merch",
    component: () => import("/vercel/path0/src/pages/shop/merch.vue")
  },
  {
    name: "shop-order-success",
    path: "/shop/order-success",
    component: () => import("/vercel/path0/src/pages/shop/order-success.vue")
  },
  {
    name: "shop-slug-backup",
    path: "/shop/slug-backup",
    component: () => import("/vercel/path0/src/pages/shop/slug-backup.vue")
  },
  {
    name: "shop-tags-black-metal-cassettes",
    path: "/shop/tags/black-metal-cassettes",
    component: () => import("/vercel/path0/src/pages/shop/tags/black-metal-cassettes.vue")
  },
  {
    name: "shop-tags-merch-bundles",
    path: "/shop/tags/merch-bundles",
    component: () => import("/vercel/path0/src/pages/shop/tags/merch-bundles.vue")
  },
  {
    name: "shop-tags-post-metal-cassettes",
    path: "/shop/tags/post-metal-cassettes",
    component: () => import("/vercel/path0/src/pages/shop/tags/post-metal-cassettes.vue")
  },
  {
    name: "shop-tags-post-metal-t-shirts",
    path: "/shop/tags/post-metal-t-shirts",
    component: () => import("/vercel/path0/src/pages/shop/tags/post-metal-t-shirts.vue")
  },
  {
    name: "shop-tags-post-rock-cassettes",
    path: "/shop/tags/post-rock-cassettes",
    component: () => import("/vercel/path0/src/pages/shop/tags/post-rock-cassettes.vue")
  },
  {
    name: "shop-tags-post-rock-t-shirts",
    path: "/shop/tags/post-rock-t-shirts",
    component: () => import("/vercel/path0/src/pages/shop/tags/post-rock-t-shirts.vue")
  }
]