import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["AllPosts","Artists","FeaturedPost","GetArtist","GetBlackMetalCassettes","GetCassettes","GetFeaturedProducts","GetMerch","GetMerchBundles","GetOffer","GetOffers","GetPost","GetPostMetalCassettes","GetPostMetalTShirts","GetPostRockCassettes","GetPostRockTShirts","GetProduct","GetProducts","GetRelease","GetShippings","GetVariant","HomePosts","LatestRelease","Releases"]}
export const GqlAllPosts = (...params) => useGql()('AllPosts', ...params)
export const GqlArtists = (...params) => useGql()('Artists', ...params)
export const GqlFeaturedPost = (...params) => useGql()('FeaturedPost', ...params)
export const GqlGetArtist = (...params) => useGql()('GetArtist', ...params)
export const GqlGetBlackMetalCassettes = (...params) => useGql()('GetBlackMetalCassettes', ...params)
export const GqlGetCassettes = (...params) => useGql()('GetCassettes', ...params)
export const GqlGetFeaturedProducts = (...params) => useGql()('GetFeaturedProducts', ...params)
export const GqlGetMerch = (...params) => useGql()('GetMerch', ...params)
export const GqlGetMerchBundles = (...params) => useGql()('GetMerchBundles', ...params)
export const GqlGetOffer = (...params) => useGql()('GetOffer', ...params)
export const GqlGetOffers = (...params) => useGql()('GetOffers', ...params)
export const GqlGetPost = (...params) => useGql()('GetPost', ...params)
export const GqlGetPostMetalCassettes = (...params) => useGql()('GetPostMetalCassettes', ...params)
export const GqlGetPostMetalTShirts = (...params) => useGql()('GetPostMetalTShirts', ...params)
export const GqlGetPostRockCassettes = (...params) => useGql()('GetPostRockCassettes', ...params)
export const GqlGetPostRockTShirts = (...params) => useGql()('GetPostRockTShirts', ...params)
export const GqlGetProduct = (...params) => useGql()('GetProduct', ...params)
export const GqlGetProducts = (...params) => useGql()('GetProducts', ...params)
export const GqlGetRelease = (...params) => useGql()('GetRelease', ...params)
export const GqlGetShippings = (...params) => useGql()('GetShippings', ...params)
export const GqlGetVariant = (...params) => useGql()('GetVariant', ...params)
export const GqlHomePosts = (...params) => useGql()('HomePosts', ...params)
export const GqlLatestRelease = (...params) => useGql()('LatestRelease', ...params)
export const GqlReleases = (...params) => useGql()('Releases', ...params)